import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styles from "./imprint.module.css"

export default function Imprint() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(imprint/imprint.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div className={styles.content}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.allMarkdownRemark.edges[0].node.html,
        }}
      />
      Diese Webseite verwendet Open-Source-Software. Details zu den verwendeten
      Lizenzen sind unter{" "}
      <Link to="/oss/" rel={`jslicense`}>
        Lizenzen
      </Link>{" "}
      zu finden.
    </div>
  )
}
