import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Imprint from "../components/imprint/imprint"

const title = `Impressum`

const ImprintPage = () => (
  <Layout title={title}>
    <SEO title={title} pathname="/impressum" />
    <Imprint />
  </Layout>
)

export default ImprintPage
